import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";

import { IDrawerType } from "../../../../wdcommon/IDrawer";
import { Manufacturer } from "../../../../wdcommon/IProduct";
import { APIService } from "./api.service";

const ezugStykkeHeights = {
  "55": 52,
  "75": 62,
  "95": 62,
  "115": 82,
  "135": 82,
  "155": 102,
  "175": 102,
  "195": 122,
  "215": 142,
  "235": 142,
  "255": 162,
  "275": 162,
  "295": 182,
  "315": 182,
  "335": 202,
  "355": 202,
  "375": 222,
  "395": 222,
};

@Injectable({
  providedIn: "root",
})
export class DrawerOptionsService {
  private _drawers: IDrawerType[];

  constructor(private api: APIService) {}

  public getUdtrDybder() {
    return this.api.getUdtrDybder();
  }

  public getIlsDybder() {
    return this.api.getIlsDybder();
  }

  public async getDrawer(type: string): Promise<IDrawerType> {
    return (await this.getDrawers()).find((d) => d.type === type);
  }

  public async getDrawers(
    type: "all" | "special" | Manufacturer.nothegger | "normal" = "all"
  ): Promise<IDrawerType[]> {
    if (!this._drawers) {
      const drawerEntitiesPromise = firstValueFrom(this.api.getDrawers());
      const specialDrawerEntitiesPromise = firstValueFrom(
        this.api.getSpecialDrawers()
      );

      let drawerEntities = (await drawerEntitiesPromise) as any[];
      if (!Array.isArray(drawerEntities)) {
        console.error("/drawers did not return an array", drawerEntities);
        drawerEntities = [];
      }

      let specialDrawerEntities = (await specialDrawerEntitiesPromise) as any[];
      if (!Array.isArray(specialDrawerEntities)) {
        console.error("/drawers/special did not return an array", drawerEntities);
        specialDrawerEntities = [];
      }

      this._drawers = drawerEntities.concat(specialDrawerEntities).map((de) => ({
        type: `${de.shortname}`,
        name: `${de.name}`,
        description: `${de.description}`,
        img: `${de.img}`,
        special: de.special === true,
        options: de.options,
        enabled: de.enabled,
        manufacturer: de.manufacturer,
        id: de.id,
      }));
    }

    switch (type) {
      case "special":
        return this._drawers.filter((d) => d.special);

      case "normal":
        return this._drawers.filter(
          (d) => !d.special && d.manufacturer !== "Nothegger"
        );

      case Manufacturer.nothegger:
        return this._drawers.filter((d) => d.manufacturer === "Nothegger");

      default:
        return this._drawers;
    }
  }

  getEzugPieceHeight(height: number): number {
    let lookupHeight: number;
    // svalesamlet - vi skal konvertere højderne til match
    switch (height) {
      case 58:
        lookupHeight = 55;
        break;
      case 84:
        lookupHeight = 95;
        break;
      case 110:
        lookupHeight = 115;
        break;
      case 136:
        lookupHeight = 135;
        break;
      case 162:
        lookupHeight = 175;
        break;
      case 188:
        lookupHeight = 195;
        break;
      case 214:
        lookupHeight = 215;
        break;
      case 240:
        lookupHeight = 235;
        break;
      case 266:
        lookupHeight = 275;
        break;
      case 292:
        lookupHeight = 295;
        break;
      default:
        lookupHeight = height;
        break;
    }
    // console.log('Getting EZUG Piece Height', height, ezugStykkeHeights[lookupHeight]);
    return ezugStykkeHeights[lookupHeight];
  }

  async getRunnerProductNumber(
    name: string,
    runnerDepth?: number,
    vaegt?: number,
    type?: string
  ) {
    const runnerProductNumber = await this.api.getRunnerConfig();

    let selected: string;
    let key: string;

    // Determine if we are dealing with "koblinger"
    if (name.includes("koblinger")) {
      if (name.includes("actro_5D")) {
        return runnerProductNumber.koblinger["5D"];
      } else if (name.includes("4D")) {
        return runnerProductNumber.koblinger["4D"];
      } else if (name.includes("quadro_v6_5d")) {
        return runnerProductNumber.koblinger["5D_v6"];
      }
    }

    key = name.split("_")[1];

    // Normalize type
    if (type?.includes("silent")) {
      type = "silent";
    } else if (type?.includes("push")) {
      type = "pushToOpen";
    } else {
      type = null;
    }

    // Determine key based on name
    const nameParts = name.split("_");
    if (nameParts[2] === "4D") {
      key = "quadro4D";
    } else if (nameParts[2] === "5D") {
      key = "actro5D";
    } else if (nameParts[2] === "v6") {
      key = name.replace("hettich_", "");
    }

    // Select the appropriate runner product number
    if (key.includes("quadro_v6_5d")) {
      selected = runnerProductNumber[`${key}_${type}`][`${runnerDepth}_${vaegt}`];
    } else if (key.includes("actro5D")) {
      selected = runnerProductNumber[key][`${runnerDepth}_${vaegt}`];
    } else if (key.includes("4D")) {
      selected = runnerProductNumber[key][type][runnerDepth];
    } else {
      selected = runnerProductNumber[key][runnerDepth];
    }

    console.log(`Selected runner product number: ${selected}`, {
      name,
      runnerDepth,
      vaegt,
      type,
      key,
    });

    return selected;
  }
}
