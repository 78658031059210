import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Constants } from "../../../../wdcommon/Constants";
import { ICompany, ICompanyAdjustments, ILogo } from "../../../../wdcommon/ICompany";
import {
  IDrawerHistory,
  IDrawerType,
  IErnstMairDrawerResponse,
  IFullErnstMairDrawerResponse,
  IPurewoodDrawer,
} from "../../../../wdcommon/IDrawer";
import {
  GlobalContentType,
  IGlobalContent,
} from "../../../../wdcommon/IGlobalContent";
import {
  CreateOrder,
  ExistingOrder,
  IExternalShopOrder,
  IExternalShopOrderResponse,
  IOrder,
  IOrderStatus,
  IOrderUser,
} from "../../../../wdcommon/IOrder";
import {
  IFrontDrillingSetup,
  IFrontExtrasPrices,
  IFrontPrices,
  IOtherPrices,
  IPrices,
  ITypePrices,
} from "../../../../wdcommon/IPrices";
import { IProducer } from "../../../../wdcommon/IProducer";
import {
  FrontCategory,
  IDynamicProduct,
  IDynamicProductWithLocales,
  IDynamicProductWithRelated,
  IFront,
  IFrontHistory,
  IFullCategoryResponse,
  IImage,
  IItemNumber,
  IOption,
  IOtherProduct,
  IProductCategoryResponse,
  IProductCategoryTreeResponse,
  Manufacturer,
} from "../../../../wdcommon/IProduct";
import { IRelatedProductSearchResult } from "../../../../wdcommon/IRelatedProduct";
import { IRunnerConfig } from "../../../../wdcommon/IRunner";
import { ISavedProject } from "../../../../wdcommon/ISavedProject";
import { ISetting } from "../../../../wdcommon/ISetting";
import { IUser } from "../../../../wdcommon/IUser";
import { environment } from "../../environments/environment";
import { ProductCategory } from "../admin/admin-products/product-category";

const API_URL = environment.apiUrl;

interface ApiCache {
  categoryImages: {
    [categoryId: string]: Promise<IImage>;
  };
  drawerTypes?: Promise<IDrawerType[]>;
  frontCategories: { [frontType: string]: Promise<IFront[]> };
  prices: {
    all?: Promise<IPrices>;
    [Manufacturer.ernstMair]: {
      prices?: Promise<IPrices>;
      upPrices?: Promise<IPrices>;
    };
    front?: Promise<IFrontPrices>;
    frontExtras?: Promise<IFrontExtrasPrices>;
    frontDrilling?: Promise<IFrontDrillingSetup>;
    other?: Promise<IOtherPrices>;
    [Manufacturer.purewood]?: Promise<IPrices>;
    runner?: Promise<ITypePrices>;
  };
  runnerConfig?: Promise<IRunnerConfig>;
  settings: {
    [key: string]: Promise<ISetting>;
  };
  sku: {
    [hash: string]: Promise<IItemNumber>;
  };
}

@Injectable({
  providedIn: "root",
})
export class APIService {
  cache: ApiCache = {
    categoryImages: {},
    frontCategories: {},
    prices: {
      [Manufacturer.ernstMair]: {},
    },
    settings: {},
    sku: {},
  };

  token: string;

  constructor(private http: HttpClient) {}

  public getSetting(key: string): Promise<ISetting> {
    if (!this.cache.settings[key]) {
      this.cache.settings[key] = firstValueFrom(
        this.http.get<ISetting>(API_URL + "/settings/" + key)
      );
    }
    return this.cache.settings[key];
  }

  public async postSetting(key: string, value: string) {
    await firstValueFrom(
      this.http.post(API_URL + "/settings/" + key, { key, value })
    );
    this.clearSettingFromCache(key);
  }

  public clearSettingFromCache(key: string) {
    delete this.cache.settings[key];
  }

  public getDrawers() {
    return this.http.get(API_URL + "/drawers");
  }

  public updateDrawer(drawer: IDrawerType) {
    return this.http.put(API_URL + "/drawers", { drawer });
  }

  // user api
  public loginUser(email: string, password: string) {
    return firstValueFrom(
      this.http.post<{ err?: string; token?: string }>(API_URL + "/users/login", {
        email,
        password,
      })
    );
  }

  public getUserValid(): Promise<IUser | null> {
    return firstValueFrom(this.http.get<IUser>(API_URL + "/users/validate"), {
      defaultValue: null,
    });
  }

  // prices
  public getPrices(): Promise<IPrices> {
    if (!this.cache.prices.all) {
      this.cache.prices.all = firstValueFrom(
        this.http.get<IPrices>(API_URL + "/prices")
      );
    }
    return this.cache.prices.all;
  }

  public getErnstMairPrices() {
    if (!this.cache.prices[Manufacturer.ernstMair].prices) {
      this.cache.prices[Manufacturer.ernstMair].prices = firstValueFrom(
        this.http.get<IPrices>(API_URL + "/prices/ernstMair")
      );
    }
    return this.cache.prices[Manufacturer.ernstMair].prices;
  }

  public getErnstMairPricesUP(): Promise<IPrices> {
    if (!this.cache.prices[Manufacturer.ernstMair].upPrices) {
      this.cache.prices[Manufacturer.ernstMair].upPrices = firstValueFrom(
        this.http.get<IPrices>(API_URL + "/prices/ernstMair/up")
      );
    }
    return this.cache.prices[Manufacturer.ernstMair].upPrices;
  }

  public getProductOptions(): Promise<IOption[]> {
    return firstValueFrom(this.http.get<IOption[]>(API_URL + "/options"));
  }

  public getRunnerConfig() {
    if (!this.cache.runnerConfig) {
      this.cache.runnerConfig = firstValueFrom(
        this.http.get<IRunnerConfig>(API_URL + "/udtraek")
      );
    }
    return this.cache.runnerConfig;
  }

  public getRunnerPrices(): Promise<ITypePrices> {
    if (!this.cache.prices.runner) {
      this.cache.prices.runner = firstValueFrom(
        this.http.get<ITypePrices>(API_URL + "/udtraek/prices")
      );
    }
    return this.cache.prices.runner;
  }

  public postNewOrder(orderData: CreateOrder) {
    return this.http.post(API_URL + "/orders/neworder", orderData);
  }

  public postExistingOrder(orderData: ExistingOrder) {
    return this.http.post(API_URL + "/orders/existingorder", orderData);
  }

  public transferOrders(orders: IOrder[]) {
    return this.http.post(API_URL + "/orders/transferorders", orders);
  }

  public createExternalShopOrder(
    externalShopOrder: IExternalShopOrder,
    order: IOrder,
    user: IUser
  ): Promise<IExternalShopOrderResponse> {
    return firstValueFrom(
      this.http.post<IExternalShopOrderResponse>(
        API_URL + "/orders/createexternalshoporder",
        { order, externalShopOrder, user }
      )
    );
  }

  public getOrderStatuses(): Observable<IOrderStatus[]> {
    return this.http.get<IOrderStatus[]>(API_URL + "/orders/statuses");
  }

  public getOrderUdsparinger(orderID) {
    return this.http.get<
      {
        drawerID: string;
        id: number;
        imageFile: {
          id: number;
          filePath: string;
          origFilename: string;
        };
      }[]
    >(API_URL + "/orders/" + orderID + "/udsparinger");
  }

  public getOrders(userID?: any): Observable<IOrder[]> {
    if (userID) {
      return this.http.get<IOrder[]>(API_URL + "/orders/user");
    } else {
      return this.http.get<IOrder[]>(API_URL + "/orders");
    }
  }

  public getOrder(id): Observable<IOrder> {
    return this.http.get<IOrder[]>(API_URL + "/orders/" + id).pipe(
      map((orders) => {
        return orders[0];
      })
    );
  }

  public getOrderContents(id) {
    return this.http.get(API_URL + "/orders/" + id + "/contents");
  }

  public getOrderByID(orderID) {
    return firstValueFrom(
      this.http.get<IOrder>(API_URL + "/orders/order-id/" + orderID)
    );
  }

  public getOtherProducts() {
    return this.http.get<IOtherProduct[]>(API_URL + "/products");
  }

  public updateOtherProduct(product: IOtherProduct) {
    return this.http.put(API_URL + "/products", { product });
  }

  public getErnstMairs(locale?: string) {
    return firstValueFrom(
      this.http.get<IErnstMairDrawerResponse[]>(
        API_URL + "/products/ernstMairDrawers",
        { params: { locale: locale } }
      )
    );
  }

  public getErnstMair(type: string): Observable<IFullErnstMairDrawerResponse> {
    return this.http.get<IFullErnstMairDrawerResponse>(
      API_URL + "/products/ernstMair",
      { params: { type: type } }
    );
  }

  public updateErnstMair(drawer: IFullErnstMairDrawerResponse): Promise<any> {
    return firstValueFrom(
      this.http.put(API_URL + "/products/ernstMair", { drawer })
    );
  }

  public getSpecialDrawers() {
    return this.http.get(API_URL + "/drawers/specials");
  }

  public getCVRInfo(cvr) {
    return this.http.get("https://cvrapi.dk/api?country=dk&search=" + cvr);
  }

  public validateCVR(cvr) {
    return this.http.get("https://cvrapi.dk/api?country=dk&vat=" + cvr);
  }

  public checkCVR(cvr) {
    return this.http.get<{ alreadyExists: boolean }>(
      API_URL + "/company/checkCVR/" + cvr
    );
  }

  public createCompany(companyInfo) {
    // ds
    return this.http.post(API_URL + "/company", companyInfo);
  }

  public getInactiveUsers(): Promise<IUser[]> {
    return firstValueFrom(this.http.get<IUser[]>(API_URL + "/users/inactive"));
  }

  public postSavedOrder(cart) {
    return this.http.post(API_URL + "/orders/save", cart);
  }

  public async postRestoredOrder(payload: {
    cart: string;
    name: string;
    user: IOrderUser;
  }) {
    return await firstValueFrom(
      this.http.post(API_URL + "/orders/restore", payload)
    );
  }

  public getSavedProjects() {
    return firstValueFrom(this.http.get<ISavedProject[]>(API_URL + "/orders/saved"));
  }

  public deleteSavedOrder(id) {
    return this.http.post(API_URL + "/orders/saved/delete", { id: id });
  }

  public deleteOrder(id) {
    return this.http.put(API_URL + "/orders/delete", { id });
  }

  public deleteUser(id) {
    return this.http.post(API_URL + "/users/delete/" + id, {});
  }

  public updateOrderStatus(id, status) {
    return this.http.put(API_URL + "/orders/status", { id, status });
  }

  public updateOrderDeliveryDate(orderID, deliveryDate) {
    return this.http.put(API_URL + "/orders/deliveryDate", {
      orderID,
      deliveryDate,
    });
  }

  public getUdtrDybder() {
    return this.http.get(API_URL + "/options/dybder/udtraek") as Observable<
      Record<string, number[]>
    >;
  }

  public getIlsDybder() {
    return this.http.get(API_URL + "/options/ils/dybder");
  }

  public getVarenr(hash): Promise<IItemNumber> {
    if (!this.cache.sku[hash]) {
      this.cache.sku[hash] = firstValueFrom(
        this.http.post<IItemNumber>(API_URL + "/orders/varenr", { hash })
      );
    }
    return this.cache.sku[hash];
  }

  // May be reintroduced in the near future
  // public getSpecificHistoryDrawer(itemno): Promise<IDrawerHistoryResponse> {
  //   return firstValueFrom(this.http.get<IDrawerHistoryResponse>(API_URL + '/drawers/itemno/' + itemno));
  // }

  public getAllUsers(): Observable<IUser[]> {
    return this.http.get<IUser[]>(API_URL + "/users");
  }

  public resetPassword(password: string, hash: string) {
    return this.http.post(API_URL + "/users/resetpassword/" + hash, { password });
  }

  public requestPasswordReset(email: string) {
    return this.http.post(API_URL + "/users/requestPasswordReset", { email });
  }

  public activateUser(id: number): Promise<IUser> {
    return firstValueFrom(
      this.http.post<IUser>(API_URL + "/users/activate", { userID: id })
    );
  }

  public allowProducerAccess(
    producer: IProducer,
    allowAccess: boolean,
    id: number
  ): Promise<boolean> {
    return firstValueFrom(
      this.http.post<boolean>(API_URL + "/users/allowProducerAccess", {
        producer: producer,
        userId: id,
        allowAccess: allowAccess,
      })
    );
  }

  public sendMail(email: string, subject: string, mail: string) {
    return this.http.post(API_URL + "/users/mail", { email, subject, mail });
  }

  public getCompanies(): Observable<ICompany[]> {
    return this.http.get<ICompany[]>(API_URL + "/company");
  }

  public getCompany(id: number) {
    return firstValueFrom(this.http.get<ICompany>(API_URL + "/company/" + id));
  }

  public getCompanyFromEconomics(id: number) {
    return this.http.get(API_URL + "/company/economics/" + id);
  }

  public getCompanyUsers(): Observable<IUser[]> {
    return this.http.get<IUser[]>(API_URL + "/company/users");
  }

  public getCompanyUserByEmail(email: string) {
    return this.http.get(API_URL + "/company/user/" + email);
  }

  public getCompanyAdjusts(companyId: number) {
    return firstValueFrom(
      this.http.get<ICompanyAdjustments>(API_URL + "/company/adjust/" + companyId)
    );
  }

  public setCompanyAdjusts(companyId: number, adjusts: ICompanyAdjustments) {
    return firstValueFrom(
      this.http.post(API_URL + "/company/adjust/" + companyId, {
        adjustments: adjusts,
      })
    );
  }

  public updateCompany(company: ICompany) {
    return this.http.put(API_URL + "/company/update", company);
  }

  public updateCompanyFromAdmin(company: ICompany, id: number) {
    company.id = id;
    return this.http.put(API_URL + "/company/updateCompany", company);
  }

  public restoreCompanyUser(user) {
    return this.http.post(API_URL + "/company/restoreuser", user);
  }

  public setUserAsCompanyAdmin(id: number) {
    return this.http.put(API_URL + "/users/setUserAsCompanyAdmin", { userid: id });
  }

  public getOtherPrices(): Promise<IOtherPrices> {
    if (!this.cache.prices.other) {
      this.cache.prices.other = firstValueFrom(
        this.http.get<IOtherPrices>(API_URL + "/prices/others")
      );
    }
    return this.cache.prices.other;
  }

  public saveDrawerHistory(history: IDrawerHistory) {
    return this.http.post(API_URL + "/drawers/history", history);
  }

  public getDrawerHistory(amount): Promise<IDrawerHistory[]> {
    return firstValueFrom(
      this.http.get<IDrawerHistory[]>(API_URL + "/drawers/history/" + amount)
    );
  }

  public createNewCompanyUser(email: string, name: string) {
    return this.http.post(API_URL + "/users/newCompanyUser", { email, name });
  }

  public confirmCompanyUser(
    name: string,
    email: string,
    password: string,
    hash: string
  ) {
    return this.http.post(API_URL + "/users/createNewCompanyUser/" + hash, {
      name,
      email,
      password,
    });
  }

  public getOrderPDF(orderID: string) {
    return this.http.get(API_URL + "/orders/pdf/" + orderID, {
      responseType: "arraybuffer",
    });
  }

  public requestPasswordChange(oldPW, newPW) {
    return this.http.put(API_URL + "/users/chpw", { oldPW, newPW });
  }

  public getCompanyLogos() {
    return firstValueFrom(this.http.get<ILogo[]>(API_URL + "/users/logos"));
  }

  public getLogo(id) {
    return firstValueFrom(this.http.get<IImage>(API_URL + "/users/logo/" + id));
  }

  public deleteLogo(id) {
    return this.http.delete(API_URL + "/users/logo/" + id);
  }

  public deleteCompany(id) {
    return firstValueFrom(this.http.delete(API_URL + "/company/" + id));
  }

  public getCategory(
    categoryId?: number,
    localeId?: string
  ): Promise<IProductCategoryResponse> {
    return firstValueFrom(
      this.http.get<IProductCategoryResponse>(
        API_URL + "/products/categoryGet/" + categoryId,
        { params: { locale: localeId } }
      )
    );
  }

  public getCategories(
    parentCategoryId?: number,
    localeId?: string
  ): Promise<IProductCategoryResponse[]> {
    return firstValueFrom(
      this.http.get<IProductCategoryResponse[]>(
        API_URL +
          "/products/categories/" +
          (parentCategoryId ? parentCategoryId : ""),
        { params: { locale: localeId } }
      )
    );
  }

  public getCategoryTree(
    categoryId: number,
    localeId: string
  ): Promise<IProductCategoryTreeResponse[]> {
    return firstValueFrom(
      this.http.get<IProductCategoryTreeResponse[]>(
        API_URL + "/products/category-tree/" + categoryId,
        { params: { locale: localeId } }
      )
    );
  }

  public saveCategory(category: ProductCategory): Promise<any> {
    return firstValueFrom(
      this.http.post(API_URL + "/products/newcategory", category)
    );
  }

  public deleteCategory(categoryId: number): Promise<any> {
    return firstValueFrom(
      this.http.delete(API_URL + "/products/deleteCategory/" + categoryId)
    );
  }

  public saveProduct(
    product: IDynamicProductWithLocales
  ): Promise<IDynamicProductWithLocales> {
    return firstValueFrom(
      this.http.post<IDynamicProductWithLocales>(API_URL + "/products", product)
    );
  }

  public saveProductImage(file: File, id: number) {
    const formData = new FormData();
    formData.append("file", file);
    return firstValueFrom(
      this.http.post(API_URL + "/products/newproductimage/" + id, formData)
    );
  }

  public saveCategoryImage(file: File, id: number) {
    const formData = new FormData();
    formData.append("file", file);
    delete this.cache.categoryImages[id];
    return firstValueFrom(
      this.http.post(API_URL + "/products/categoryimage/" + id, formData)
    );
  }

  public getDynamicProductImage(id) {
    return firstValueFrom(
      this.http.get<IImage>(API_URL + "/products/productimage/" + id)
    );
  }

  public getCategoryImage(id: number) {
    if (!this.cache.categoryImages[Constants.specialsCategoryBrandIdPrefix + id]) {
      this.cache.categoryImages[Constants.specialsCategoryBrandIdPrefix + id] =
        firstValueFrom(
          this.http.get<IImage>(API_URL + "/products/categoryimage/" + id)
        );
    }
    return this.cache.categoryImages[Constants.specialsCategoryBrandIdPrefix + id];
  }

  // public deleteProductImage(id) {
  //   return firstValueFrom(this.http.delete(API_URL + '/products/productimage/' + id));
  // }

  public deleteProduct(productId: number) {
    return firstValueFrom(this.http.delete(API_URL + "/products/" + productId));
  }

  public getProducts(
    categoryId?: number,
    locale?: string
  ): Promise<IDynamicProduct[]> {
    return firstValueFrom(
      this.http.get<IDynamicProduct[]>(
        API_URL + "/products/dynamicproducts/" + (categoryId ? categoryId : ""),
        { params: { locale: locale } }
      )
    );
  }

  public getAllProducts(locale?: string): Promise<IDynamicProduct[]> {
    return firstValueFrom(
      this.http.get<IDynamicProduct[]>(API_URL + "/products/dynamicproducts/all", {
        params: { locale: locale },
      })
    );
  }

  public getProduct(productId: number, localeId: string) {
    return firstValueFrom(
      this.http.get<IDynamicProductWithRelated>(
        API_URL + "/products/dynamicproducts/details/" + productId,
        { params: { locale: localeId } }
      )
    );
  }

  public getProductForEditing(productId: number, localeId: string) {
    return firstValueFrom(
      this.http.get<IDynamicProductWithLocales>(
        API_URL +
          "/products/dynamicproducts/full/" +
          productId +
          "?forLocale=" +
          localeId
      )
    );
  }

  public updateHettich(): Promise<any> {
    return firstValueFrom(this.http.get(API_URL + "/products/updateHettichPrices"));
  }

  public getCategoryForEditing(categoryId: number): Promise<IFullCategoryResponse> {
    return firstValueFrom(
      this.http.get<IFullCategoryResponse>(
        API_URL + "/products/category/full/" + categoryId
      )
    );
  }

  public getProductImage(id) {
    return this.http.get(API_URL + "/products/prodimg/" + id);
  }

  public addMissingTranslation(language: string, key: string) {
    return this.http.put(
      API_URL +
        `/options/translations/missing/${encodeURIComponent(
          language
        )}/${encodeURIComponent(key)}`,
      undefined
    );
  }

  public searchForRelatedProducts(
    keyword: string,
    localeId: string,
    productId: number
  ) {
    return firstValueFrom(
      this.http.get<IRelatedProductSearchResult[]>(
        API_URL +
          "/relatedproducts/?keyword=" +
          encodeURIComponent(keyword) +
          "&forLocale=" +
          localeId +
          "&forProductId=" +
          productId
      )
    );
  }

  public log(tags: any, message?: any, ...optionalParams: any[]) {
    return firstValueFrom(
      this.http.post(API_URL + "/logging", { message, tags, optionalParams })
    );
  }

  public getPurewoodDrawers(onlyEnabled = true): Promise<IPurewoodDrawer[]> {
    return firstValueFrom(
      this.http.get<IPurewoodDrawer[]>(API_URL + "/products/purewoodDrawers", {
        params: { onlyEnabled: onlyEnabled ? "true" : "false" },
      })
    );
  }

  public getPurewoodDrawer(type: string): Promise<IPurewoodDrawer> {
    return firstValueFrom(
      this.http.get<IPurewoodDrawer>(API_URL + "/products/purewoodDrawer", {
        params: { shortname: type.replace("purewood-", "") },
      })
    );
  }

  public getPurewoodDrawerPrices(): Promise<IPrices> {
    if (!this.cache.prices[Manufacturer.purewood]) {
      this.cache.prices[Manufacturer.purewood] = firstValueFrom(
        this.http.get<IPrices>(API_URL + "/prices/purewoodDrawers")
      );
    }
    return this.cache.prices[Manufacturer.purewood];
  }

  // Purewood fronts
  public getFronts(category: FrontCategory): Promise<IFront[]> {
    if (!this.cache.frontCategories[category]) {
      this.cache.frontCategories[category] = firstValueFrom(
        this.http.get<IFront[]>(API_URL + "/fronts/getCategory/" + category)
      );
    }
    return this.cache.frontCategories[category];
  }

  public async getFront(
    category: FrontCategory,
    frontTypeId: string
  ): Promise<IFront> {
    const categoryFronts = await this.getFronts(category);
    return categoryFronts.find((x) => x.id === frontTypeId);
  }

  public getFrontDrillingSetup(): Promise<IFrontDrillingSetup> {
    if (!this.cache.prices.frontDrilling) {
      this.cache.prices.frontDrilling = firstValueFrom(
        this.http.get<IFrontDrillingSetup>(API_URL + "/fronts/drillingSetup")
      );
    }
    return this.cache.prices.frontDrilling;
  }

  public getFrontPrices(): Promise<IFrontPrices> {
    if (!this.cache.prices.front) {
      this.cache.prices.front = firstValueFrom(
        this.http.get<IFrontPrices>(API_URL + "/fronts/prices")
      );
    }
    return this.cache.prices.front;
  }

  public getFrontExtraPrices(): Promise<IFrontExtrasPrices> {
    if (!this.cache.prices.frontExtras) {
      this.cache.prices.frontExtras = firstValueFrom(
        this.http.get<IFrontExtrasPrices>(API_URL + "/fronts/prices/extra")
      );
    }
    return this.cache.prices.frontExtras;
  }

  public getFrontDesignPdf(fileId: string) {
    return firstValueFrom(
      this.http.get(API_URL + "/fronts/get-design-pdf/" + fileId, {
        responseType: "arraybuffer",
      })
    );
  }

  public saveFrontHistory(history): Promise<any> {
    return firstValueFrom(this.http.post(API_URL + "/fronts/saveHistory", history));
  }

  public getFrontHistory(amount): Promise<IFrontHistory[]> {
    return firstValueFrom(
      this.http.get<IFrontHistory[]>(API_URL + "/fronts/getHistory/" + amount)
    );
  }

  // End Purewood fronts

  // Start Global Content
  public getAllGlobalContent(): Promise<IGlobalContent[]> {
    return firstValueFrom(
      this.http.get<IGlobalContent[]>(API_URL + "/globalContent/")
    );
  }

  public getGlobalContentOfType(type: GlobalContentType): Promise<IGlobalContent[]> {
    return firstValueFrom(
      this.http.get<IGlobalContent[]>(API_URL + "/globalContent/" + type)
    );
  }

  public getGlobalContent(type: string, key: string): Promise<IGlobalContent> {
    return firstValueFrom(
      this.http.get<IGlobalContent>(API_URL + "/globalContent/" + type + "/" + key)
    );
  }

  public setGlobalContent(globalContent: Partial<IGlobalContent>) {
    return firstValueFrom(
      this.http.post(
        API_URL + "/globalContent/" + globalContent.type + "/" + globalContent.key,
        globalContent
      )
    );
  }

  public getGlobalContentImage(type: string, key: string): Promise<IImage | null> {
    return firstValueFrom(
      this.http.get<IImage>(API_URL + "/globalContent/image/" + type + "/" + key)
    );
  }

  public saveGlobalContentImage(file: File, type: string, key: string) {
    const formData = new FormData();
    formData.append("file", file);
    return firstValueFrom(
      this.http.post(API_URL + "/globalContent/image/" + type + "/" + key, formData)
    );
  }

  public deleteGlobalContentImage(type: string, key: string) {
    return firstValueFrom(
      this.http.delete(API_URL + "/globalContent/image/" + type + "/" + key)
    );
  }

  // End Global Content
}
