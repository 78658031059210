import { isTrue } from "./dynamic-order-tools";

export class RunnerHelper {
  public static isQuadro4DRunner(runnerMarkValue: string): boolean {
    return isTrue(
      [runnerMarkValue, "contains", "hettich"],
      [runnerMarkValue, "contains", "4D"]
    );
  }

  public static isActro5DRunner(runnerMarkValue: string): boolean {
    return isTrue(
      [runnerMarkValue, "contains", "hettich"],
      [runnerMarkValue, "contains", "5D"],
      [runnerMarkValue, "!contains", "v6"]
    );
  }

  public static isQuadroV65dRunner(runnerMarkValue: string): boolean {
    return isTrue(
      [runnerMarkValue, "contains", "hettich"],
      [runnerMarkValue, "contains", "5D"],
      [runnerMarkValue, "contains", "v6"]
    );
  }

  public static is5DRunner(runnerMarkValue: string): boolean {
    return [this.isActro5DRunner, this.isQuadroV65dRunner].some((fn) =>
      fn(runnerMarkValue)
    );
  }
}
