import {
  ICutleryTrayOptions,
  ILogoOptions,
  IRearUdsparingOptions,
  IReverseEnglisherZug,
  IReverseEnglisherZugSpecial,
  ISiphonCuttingOptions,
  ITraesorterOverfladeOptions,
} from "./ICartItem";
import { OptionProperty } from "./IProduct";
import { IUser } from "./IUser";

export interface IDrawerHistory {
  id?: number;
  itemno: string;
  description: string;
  attributes?: object;
  contents: Partial<IDrawerOptions>;
  user?: IUser;
}

export interface IErnstMairDrawerResponse {
  id: number;
  name: string;
  img: string;
  description: string;
  type: string;
  enabled: boolean;
}

interface IErnstMairLocaleResponse {
  id: number;
  lang: string;
  description: string;
}

export interface IFullErnstMairDrawerResponse {
  id: number;
  name: string;
  img: string;
  type: string;
  locales?: IErnstMairLocaleResponse[];
  enabled: boolean;
}

export enum SlideListOptionValue {
  slideYes = "ja",
  slideYesPlusSix = "ja6mm",
  slideNo = "nej",
}

export const SlideListOptions = [
  {
    label: "Ja",
    value: SlideListOptionValue.slideYes,
  },
  {
    label: "foeringsListeJa6mm",
    value: SlideListOptionValue.slideYesPlusSix,
  },
  {
    label: "Nej",
    value: SlideListOptionValue.slideNo,
  },
];

export enum ShimsOptionValue {
  nej = "nej",
  x25 = "25x485x50",
  x30 = "30x485x50",
  x40 = "40x485x50",
  x25x2 = "25x485x50 x2",
  x30x2 = "30x485x50 x2",
  x40x2 = "40x485x50 x2",
}

export const ShimsOptions = [
  {
    label: "Ingen afstandsliste",
    value: ShimsOptionValue.nej,
    articleNumber: null,
    amount: 0,
  },
  {
    label: ShimsOptionValue.x25,
    value: ShimsOptionValue.x25,
    articleNumber: "10.0010",
    amount: 1,
  },
  {
    label: ShimsOptionValue.x30,
    value: ShimsOptionValue.x30,
    articleNumber: "10.0133",
    amount: 1,
  },
  {
    label: ShimsOptionValue.x40,
    value: ShimsOptionValue.x40,
    articleNumber: "10.9995",
    amount: 1,
  },
  {
    label: ShimsOptionValue.x25x2,
    value: ShimsOptionValue.x25x2,
    articleNumber: "10.0010",
    amount: 2,
  },
  {
    label: ShimsOptionValue.x30x2,
    value: ShimsOptionValue.x30x2,
    articleNumber: "10.0133",
    amount: 2,
  },
  {
    label: ShimsOptionValue.x40x2,
    value: ShimsOptionValue.x40x2,
    articleNumber: "10.9995",
    amount: 2,
  },
];

export interface IPurewoodDrawer {
  id: number;
  shortname: string;
  enabled: boolean;
}

export interface IDrawerOptions extends ITraesorterOverfladeOptions {
  type: string;

  [OptionProperty.woodQuality]: string;
  [OptionProperty.joint]: string;

  [OptionProperty.thickness]: string;

  [OptionProperty.runnerMark]: string;
  [OptionProperty.runnerType]: string;
  [OptionProperty.runnerLoad]?: number;
  [OptionProperty.runnerPushToOpenSilent]?: boolean;
  [OptionProperty.synchronisationBar]?: boolean;

  [OptionProperty.runnerDepth]: number;
  [OptionProperty.drawerOuterWidth]: number;
  [OptionProperty.premountedCoupling]: boolean;
  [OptionProperty.couplingAlongside]: boolean;

  [OptionProperty.shimsSelection]?: ShimsOptionValue;
  [OptionProperty.slideList]?: string;
  [OptionProperty.drawerBase]: string;
  [OptionProperty.bottomUnmounted]: boolean;
  [OptionProperty.fscCertified]: boolean;
  udtraekLeveret: boolean;
  udtraekDybdeJustering?: boolean;
  frontHoejde: number;
  frontBredde: number;
  skuffeHoejde: number;
  skuffeBredde: number;
  skuffeDybde: number;

  udsparing: ISiphonCuttingOptions | IRearUdsparingOptions;
  bestikindsats: ICutleryTrayOptions;
  logo: ILogoOptions;
  greb: string;

  reverseEnglisherZug: IReverseEnglisherZug;
  reverseEnglisherZugSpecial: IReverseEnglisherZugSpecial;
  rearEnglisherZug: IReverseEnglisherZug;

  boringFront: boolean;
  boringCutleryTray: boolean;
  skuffe20mmFront: boolean;
  straightLine: boolean;
  bundSkruet: boolean;
  boerstet: boolean;
  hulboring: boolean;
  isUpPrice: boolean;
}

export interface IDrawerType {
  id: number;
  type: string;
  img: string;
  name: string;
  description: string;
  special: boolean;
  manufacturer?: "Nothegger";
  options: Partial<IDrawerOptions>;
  enabled: boolean;
}
